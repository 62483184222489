'use strict';

var Utils = require("../components/Utils.js");
var React = require("react");
var Layout = require("../components/Layout.js");
var Gatsby = require("gatsby");
var Article = require("../components/Article.js");
var ReactHelmet = require("react-helmet");

function IndexPageTemplate$Navigation(Props) {
  var pageContext = Props.pageContext;
  var page = pageContext.page;
  var pages = pageContext.pages;
  var prev = pageContext.prev;
  var next = pageContext.next;
  var range = /* array */[];
  for(var i = 1; i <= pages; ++i){
    range.push(i);
  }
  return React.createElement("nav", {
              id: "page-nav"
            }, prev !== undefined ? React.createElement(Gatsby.Link, {
                    to: prev,
                    rel: "prev",
                    className: "extend prev",
                    children: "« Prev",
                    key: "prev"
                  }) : null, range.map((function (i) {
                    var match = i === page;
                    if (match) {
                      return React.createElement("span", {
                                  key: String(i),
                                  className: "pageNumber current"
                                }, String(i));
                    } else {
                      return React.createElement(Gatsby.Link, {
                                  to: Utils.indexPagePathFormatter(i),
                                  className: "pageNumber",
                                  children: String(i),
                                  key: String(i)
                                });
                    }
                  })), next !== undefined ? React.createElement(Gatsby.Link, {
                    to: next,
                    rel: "next",
                    className: "extend next",
                    children: "Next »",
                    key: "next"
                  }) : null);
}

var Navigation = {
  make: IndexPageTemplate$Navigation
};

function IndexPageTemplate(Props) {
  var pageContext = Props.pageContext;
  return React.createElement(Layout.make, {
              children: null
            }, React.createElement(ReactHelmet.Helmet, {
                  title: "Home"
                }), pageContext.nodes.filter((function (post) {
                      return post.node.frontmatter.title.length > 0;
                    })).map((function (post) {
                    var node = post.node;
                    var id = node.id;
                    var slug = node.fields.slug;
                    var excerpt = node.excerpt;
                    var frontmatter = node.frontmatter;
                    return React.createElement(Article.make, {
                                id: id,
                                frontmatter: frontmatter,
                                slug: slug,
                                children: null,
                                key: id
                              }, React.createElement("p", undefined, excerpt), React.createElement("p", {
                                    className: "article-more-link"
                                  }, React.createElement(Gatsby.Link, {
                                        to: slug,
                                        children: "Read More"
                                      })));
                  })), React.createElement(IndexPageTemplate$Navigation, {
                  pageContext: pageContext
                }));
}

var make = IndexPageTemplate;

exports.Navigation = Navigation;
exports.make = make;
/* react Not a pure module */
